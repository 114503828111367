let dataRules = {
    车主通用券:`1.本红包仅支持支付宝车生活相关的加油、充电、高速
MTC、停车场景，以及支付宝代驾小程序、支付宝租车小程序、支付宝洗车小程序、ETC服务小程序使用。车主红包具体支持核销场景及核销规则如下：
(1)加油：本红包油卡支付不可用。红包使用范围：用户可在支付宝首页【出行-车生活-加油】展示的油站完成一键加油或选择对应油枪的线上订单，并选择支付宝在线支付时使用。
(2)充电：红包仅限在支付宝-首页【出行】-【车生活】-【充电】展示的充电品牌充电站使用，仅适用于汽车充电订单。
(3)高速MTC:红包仅限在走高速人工通道缴过路费时【使用支付宝支付】可用。
(4)停车：1.用户进入【支付宝停车】小程序，输入车牌号查询缴费，支付时自动抵扣；2.支持全国支付宝缴费停车场支付使用(可至支付宝APP-“附近停车”小程序-推荐停车场查看停车场分布);
(5)代驾：1.打开支付宝首页【出行-车生活-代驾】,下单呼叫代驾，完成订单支付时直接抵扣；2.在支付宝首页搜索“支付宝代驾”,点击进入【支付宝代驾】服务，下单呼叫代驾，完成订单支付时直接抵扣；
(6)租车：用户可在【支付宝租车】小程序中选择展示红包优惠的车辆使用，仅限年满18周岁拥有驾照的用户租车使用。
(7)洗车：1.打开支付宝首页【出行-车生活-特价洗车】,下单使用；2.在支付宝首页搜索“支付宝特价洗车”,点击进入【支付宝特价洗车】服务，下单使用；
(8)ETC:仅限在支付宝小程序「ETC服务」办理客车ETC套餐使用(不包含建行免费款)。支付客车ETC办理费用时自动抵扣。

2.红包在交易时支持叠加使用，最多叠加10个。本红包不可兑换现金，不可找零。

3.权益使用有效期自领取之时起至30*24h。若使用红包的交易发生退款，红包将退回到原支付宝账号，使用有效期不变，退还红包在原有效期内可继续使用；如在原有效期外，则红包失效，不支持再次使用。
4.本红包仅限在中国大陆地区使用。本红包限实名认证用户才可使用。
5.如对本活动有疑问的，可通过官方热线4001180238进行咨询。
6.如果用户存在或涉嫌违法违规行为情况(包括不限于洗钱、虚假交易、作弊、套现、赌博、刷信用、支付宝基于自身风险政策或策略认定的风险交易等)的，支付宝将撤销用户活动参与资格和奖品使用资格，并有权回收用户已经获得的奖励权益(包括已消费金额)。请特别关注：淘宝集市购买Q币点卡、影视会员业务、游戏视频网站的一键支付业务、无卡支付业务、货到付款业务、外部商户独立担保交易等业务无法使用红包支付。
7.活动主办方可能需要根据活动的实际举办情况对活动规则依法进行变更；若有变更，活动主办方将以活动页面告知等方式及时通知；
8.本活动开展期间，如出现不可抗力等情况，例如发生自然灾害事件、遭受网络攻击或电信故障、停机维护、
疫情、活动受法律法规、监管机构要求或政策指令需要LII停止举办或调整，活动主办方单方暂停、中止或终止提供服务的，可免于承担责任。请特别关注：淘宝集市购买Q币点卡、影视会员业务、游戏视频网站的一键支付业
务、无卡支付业务、货到付款业务、外部商户独立担保交易等业务无法使用红包支付!`,
腾讯视频:`1.充值成功后，打开腾讯视频APP-使用手机号登录。
2.本商品不支持电视，仅支持手机、电脑 、平板三端。

3.到账后有效期30天，过期作废，请及时使用。`,
爱奇艺:`1.充值成功后，打开爱奇艺APP-使用手机号登录。
2.本会员不支持电视端使用，仅支持手机、电脑和平板三端使用。

3.到账后有效期30天，过期作废，请及时使用。`,
优酷视频:`1.充值成功后，打开优酷视频APP-使用手机号登录。
2.本商品不支持电视端，仅支持手机、电脑、平板使用。
3.PC端会员同一时间只能登录一个会员设备。
4.到账后有效期30天，过期作废，请及时使用。`,
哔哩哔哩:`1.充值成功后，打开哔哩哔哩APP-使用手机号登录。

2.本品仅支持手机、电脑、平板使用，不支持电视端。

3.到账后有效期30天，过期作废，请及时使用。`,
芒果TV:`1.充值成功后，打开芒果TV APP-使用手机号登录。

2.适用平台：仅支持手机 、平板、电脑三端。

3.到账后有效期30天，过期作废，请及时使用。`,
喜马拉雅:`1.充值成功后，打开喜马拉雅APP-使用手机号登录。

2.请确定充值账号的正确性，虚拟商品，一经充值成功，无法退货。

3.到账后有效期30天，过期作废，请及时使用。`,
网易云:`1.请使用充值号码（充值号码与绑定号码需一致），登陆网易云音乐APP使用。

2.到账后有效期30天，过期作废，请及时使用。`,
QQ音乐:`1.本商品为QQ音乐会员直充，支持在QQ音乐上播放、下载高品质及版权产品，享受会员专享特权。

2.充值成功后，打开QQ音乐APP-使用手机号登录。

3.到账后有效期30天，过期作废，请及时使用。`,
酷我音乐:`1.登录酷狗音乐APP-我的或电脑端头像查看到账信息；具体会员权益以APP展示为准。

2.到账后有效期30天，过期作废，请及时使用。`,
滴滴打车券:`1、使用方式：请用充值手机号登录滴滴APP-【我的】-【钱包】-【优惠券】查看使用。本券自领取日起30天内有效，请及时使用，过期作废。
2、使用规则：
（1）一笔订单只能使用一张优惠券，并且只能抵扣充值到账户之后的生成的订单，优惠券不拆分、不找零，请合理选择是否使用优惠券，优惠不足部分需用电子支付方式补足。拼车、特惠快车无法使用。
（2）优惠券选券排序：
① 优先选可抵扣金额最大的；
② 可抵扣金额一样的情况下，选未用尽金额更小的；
③ 可抵扣金额一样，未用尽金额 一样，选择过期时间更近的。`,
滴滴专车券:`1、使用方式：请用充值手机号登录滴滴APP-【我的】-【钱包】-【优惠券】查看使用。本券自领取日起30天内有效，请及时使用，过期作废。
2、使用规则：
（1）一笔订单只能使用一张优惠券，并且只能抵扣充值到账户之后的生成的订单，优惠券不拆分、不找零，请合理选择是否使用优惠券，优惠不足部分需用电子支付方式补足。拼车、特惠快车无法使用。
（2）优惠券选券排序：
① 优先选可抵扣金额最大的；
② 可抵扣金额一样的情况下，选未用尽金额更小的；
③ 可抵扣金额一样，未用尽金额 一样，选择过期时间更近的。`,
高德打车券:`1、使用方式：请用充值手机号登录[高德地图] app，点击[我的]-[钱包卡券]-[券]处查看使用。本券自领取日起7天内有效，请及时使用，过期作废。
2、使用规则：
（1）仅限在高德地图端内打车时使用，出租车、一口价订单不能使用。
（2）同一订单优惠券最多可使用一张，不可叠加使用;券仅限一次性使用，不可拆分，不可转让，不可提现。
（3）对券的使用等有任何疑问可以拨打客服电话: 4001002501`

}
export default dataRules;