<template>
  <div class="car-owner-membership">
    <div class="tag">- 以下福利每30天可领取一次 -</div>

    <div class="welfare" v-for="(value, key, index) in list" :key="index">
      <div class="title">
        福利{{ index == 0 ? "一" : index == 1 ? "二" : index == 2 ? "三" : "" }}
        <span class="annotation" v-if="index == 1 && title == '车主畅享会员'">
          视听会员N选一
        </span>
        <span class="annotation" v-if="index == 2 && title == '车主畅享会员'">
          打车券三选一
        </span>
      </div>
      <template v-if="value.length == 1">
        <div class="card" v-for="(item, dex) in value" :key="dex">
          <div class="picture">
            <img :src="item.item_icon" />
          </div>
          <div class="cont">
            <div class="num">{{ item.item_price }}元{{ item.item_note }}</div>
            <div class="name">{{ item.item_name }}</div>
          </div>
          <div v-if="state(item.item_name)" class="btn prohibit">已领取</div>
          <div v-else class="btn" @click="handleEquityReceive(item)">立即领取</div>
        </div>
      </template>
      <template v-else>
        <ul class="list">
          <li
            :class="[
              'list-item',
              { active: carState(value).name == item.item_name },
              {
                prohibit:
                  carState(value).state && carState(value).name !== item.item_name,
              },
            ]"
            v-for="(item, dex) in value"
            :key="dex"
            @click="handleEquityReceive(item, carState(value).state)"
          >
            <div class="picture">
              <img :src="item.item_icon" />
            </div>
            <div class="money">{{ item.item_price }}元{{ item.item_note }}</div>
            <div class="name">{{ item.item_name }}</div>
          </li>
        </ul>
      </template>
    </div>
    <div class="tag2" @click="showInstructions">使用说明</div>
    <v-alert
      :visibility.sync="alert.visibility"
      :title="alert.title"
      :message="alert.message"
      :rulestype="alert.rulestype"
      @handleSubmit="handleSubmit"
      @tocheck="tocheck"
    />
  </div>
</template>

<script>
import VAlert from "./dialog/alert.vue";
import { equityList, equityReceive } from "@/api/carOwner_zhengxing/receive.js";
import { mapGetters, mapActions } from "vuex";
import { setAppName } from "@/utils/common";
import dataRules from "./data.js";

export default {
  components: {
    VAlert,
  },
  data() {
    return {
      alert: {
        visibility: false,
        title: "使用说明",
        message: "",
      },
      list: [],
      title: "",
      appName: "",
      useRules:{},
      row:{}
    };
  },
  computed: {
    ...mapGetters(["carOwner_zhengxing_token", "carOwner_zhengxing_user_info"]),
    state() {
      return (name) => {
        const data = this.carOwner_zhengxing_user_info.exchanged_items[this.title];
        return (data && data.includes(name)) || false;
      };
    },
    carState() {
      return (array) => {
        let state = false;
        let name = "";
        const data = this.carOwner_zhengxing_user_info.exchanged_items[this.title] || [];
        for (const item of array) {
          if (data.includes(item.item_name)) {
            state = true;
            name = item.item_name;
            break;
          }
        }
        return {
          state,
          name,
        };
      };
    },
  },
  created() {
    const { title = "" } = this.$route.query;
    this.title = title;
    document.title = title;
    this.getList();
    this["carOwner_zhengxing/getUserInfo"]();
    this.appName = setAppName();
    this.dataRules = dataRules;
    console.log(this.dataRules)
  },
  methods: {
    ...mapActions(["carOwner_zhengxing/getUserInfo"]),
    async getList() {
      const res = await equityList({ item_require: this.title });

      const groupedCoupons = res.item.reduce((acc, curr) => {
        const exchangeType = curr.item_exchange_type;
        if (!acc[exchangeType]) {
          acc[exchangeType] = [];
        }
        acc[exchangeType].push(curr);
        return acc;
      }, {});
      this.list = groupedCoupons;

      // const groupedArray = Object.entries(groupedCoupons).map(([type, coupons]) => ({
      //   exchange_type: type,
      //   coupons,
      // }));
      // console.log(groupedCoupons);
    },
    async handleEquityReceive(row, state) {
      console.log(this.appName,row,state);
      this.row = row;
      // if(true){//提交时要改
      if(this.appName == "huanxinxiang-hailuo"){
        if(state){//兑换过使用说明
          if (!this.dataRules[row.item_name]) return;
          this.alert = {
            visibility: true,
            title: "使用说明",
            message: this.dataRules[row.item_name] || "",
          };

        }else{//false还未兑换过使用规则
          if (!this.dataRules[row.item_name]) return;
          this.alert = {
            visibility: true,
            title: "使用说明",
            message: this.dataRules[row.item_name] || "",
            rulestype : 2,
          };
        }
      }else{
        if (state) return;
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          mask: true,
          message: "加载中...",
        });
        const { item_name = "" } = row;
        const res = await equityReceive(this.carOwner_zhengxing_token, {
          item_name,
          item_require: this.title,
        });
        this.$toast.clear();
        if (res.code === 0) {
          this["carOwner_zhengxing/getUserInfo"]();
          // this.$toast.success(res.message);
          this.alert = {
            visibility: true,
            title: "领取成功",
            message: res.message,
            rulestype : 3,
          };
        } else {
          // this.$toast.fail(res.message);
          this.alert = {
            visibility: true,
            title: "领取失败",
            message: res.message,
            rulestype : 4,
          };
        }
      }
      
    },
    async handleSubmit(){
      this.visibility = false;
      // if (state) return;
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const { item_name = "" } = this.row;
      const res = await equityReceive(this.carOwner_zhengxing_token, {
        item_name,
        item_require: this.title,
      });
      this.$toast.clear();
      if (res.code === 0) {
        this["carOwner_zhengxing/getUserInfo"]();
        // this.$toast.success(res.message);
        this.alert = {
            visibility: true,
            title: "领取成功",
            message: res.message,
            rulestype : 3,
          };
      } else {
        // this.$toast.fail(res.message);
        this.alert = {
            visibility: true,
            title: "领取失败",
            message: res.message,
            rulestype : 4,
          };
      }
    },
    tocheck(){
      this.visibility = false;
      this.$router.push("/carOwner_zhengxing/receive/record")
    },
    showInstructions() {
      const data = {
        车主会员: `一、车主通用券:
1.本红包仅支持支付宝【出行-车生活】相关的加油、充电、高速MTC、停车场景，以及支付宝代驾小程序、支付宝租车小程序、支付宝洗车小程序、ETC服务小程序使用。
2.本红包在交易时支持叠加使用，最多叠加10个。本红包不可兑换现金，不可找零。
3.若使用红包的交易发生退款，红包将退回到原支付宝账号，使用有效期不变，退还红包在原有效期内可继续使用；如在原有效期外，则红包失效，不支持再次使用。
4.本红包仅限在中国大陆地区使用，限实名认证用户才可使用。
5.本券到账后有效期30天，过期作废，请及时使用。

二、滴滴代驾优惠券
1.代驾券仅限滴滴出行 APP 或微信客户端内代驾业务线上支付时使用(代驾券不可抵扣司机报单订单、路桥费、纯等候费)
2.代驾券仅限在滴滴出行-代驾业务开通的城市使用。
3.代金券充值自领取之日起有 30 天的有效，过期不退不换:滴滴订单时间需在券到账时间之后方可用，券到账前已经开始的订单无法使用
4.只能用于支付券码到帐滴滴钱包后发起的滴滴订单，在领取券码前已发起的订单无法支付

二、滴滴快车优惠券
1.本券仅支持账号绑定手机号码充值。请用户在充值前确保号码已开通滴滴账号。
2.本券为虚拟商品，一经提交充值不支持退换。
3.一笔订单只能使用一张优惠券，并且只能抵扣充值到账户之后的生成的订单，优惠券不拆分、不找零，请合理选择是否使用优惠券，优惠不足部分需用电子支付方式补足。拼车、特惠快车无法使用。
4.本券到账后有效期30天，过期作废，请及时使用。

三、滴滴专车优惠券
1.本券仅支持账号绑定手机号码充值。请用户在充值前确保号码已开通滴滴账号。
2.本券为虚拟商品，一经提交充值不支持退换。
3.一笔订单只能使用一张优惠券，并且只能抵扣充值到账户之后生成的订单，优惠券不拆分、不找零，请合理选择是否使用优惠券。
4.本券到账后有效期30天，过期作废，请及时使用。

四、高德打车优惠券
1.本券仅支持账号绑定手机号码充值，请用户在充值前确保号码已开通高德账号。
2.本券仅限在高德地图APP打车时使用，出租车、一口价订单不能使用。
3.同一订单优惠券最多可使用一张，不可叠加使用;券仅限一次性使用，不可拆分，不可转让，不可提现。
4.本券到账后有效期7天，过期作废，请及时使用。`,
        美食会员: `一、奈雪的茶代金券
1、本券仅支持账号绑定手机号码充值。请用户在充值前确保号码已开通奈雪的茶账号；
2、电子券为一次性产品，不可分次使用，不可兑换现金，不找零，不与其他优惠同享。
3、请确定充值账号正确性；虚拟商品，一经充值成功，无法退货。
4、充值到账户有效期30天，请及时使用，过期作废

二、霸王茶姬代金券
1、本券需在”霸王茶姬”微信或支付宝小程序进行兑券。
2、【适用商品】全品类：所有标准单杯茶饮品类，不包括霸王周边产品、套餐产品（门店供应的茶饮产品类型，以各门店实际情况为准）
3、【适用门店】全国区域已开通“霸王茶姬点单小程序”的门店使用(部分高铁、机场、景区门店不可用)
4、【适用场景】本券支持“霸王茶姬点单小程序”【门店自取】 、【外卖点单】订单可用，不支持【门店线下核销】
5、【券有效期】自兑券之日起30个自然日内有效，请及时使用，过期作废。

三、瑞幸咖啡代金券
1.本券仅限瑞幸咖啡APP内使用。
2.充值成功后将在10分钟内到账，请到“luckin coffee”APP-我的-咖啡钱包中查看使用。
3.瑞幸咖啡饮品券适用于自制饮品，大师咖啡、水果茶等。饮品套餐、甜品 、烘焙轻食、零食、果汁、坚果、酸奶、周边潮品等不能使用。
4.瑞幸咖啡饮品券可抵扣相应面额的商品费用（注意是商品原价，如23元代金券，只能兑换原价23元的商品），但不包含风味糖浆、奶油等辅料及配送费，优惠不同享，不设找零，超额需要补差价，一旦充值成功，不退换。
5./*  */有效期30天以内，有效期请以券面数据为准，请及时使用，过期作废。 

四、库迪咖啡代金券
1、本券为手机号充值，若用户未注册库迪咖啡会员，在充值成功后，库迪咖啡将会为您自动注册会员，用户可直接打开库迪咖啡微信小程序 /APP，选择“我的 - 代金券”即可查看权益
2、本产品为虚拟产品，一经充值成功后无法进行退换。
3、结算时，本品可抵扣全场任意饮品相应面额的商品费用，但不包含风味糖浆、小食甜点、美食热狗、早餐套餐及配送费。
4、每张饮品券只能抵扣一杯饮品相应面额的商品费用，不能拆分为多次使用。在一张订单中购买多杯饮品时，可以使用多张饮品券，每杯饮品仅限使用一张饮品券。
5、饮品券有效期为自充值成功之日起 30 天，请及时使用，过期作废。

五、喜茶代金券
1、微信搜索“喜茶GO”喜茶GO点击“我的”—“兑换中心-喜茶券”，输入兑换码后即可兑换喜茶券。兑换成功后，用户可前往我的“我的”—“喜茶券”中进行查看及使用。
2、同一兑换码仅可兑换一张喜茶券，兑换成功后兑换码即失效，不可重复兑换。
3、兑换码兑换成功后可在喜茶GO小程序直接下单使用，也可在线下出示会员码进行使用。
4、优惠券有效期为30天，请及时使用，过期作废。

六、美团外卖代金券
1.本券支持美团外卖绑定手机号充值。
2.充值成功后，请到“美团外卖”APP中“我的”-“红包”中查看使用。
3、外卖券与其他优惠不同享，不可叠加使用，不支持到店自取，限登录和收餐手机号为充值号码使用。一个外卖订单只可用一张优惠券。
4、虚拟商品，一经充值提交，不支持退换。
5、外卖券有效期30天，请及时使用，过期作废。

七、周黑鸭代金券
1、本券需在“周黑鸭官方旗舰店”微信公众号【兑换专区】兑换及使用。
2、本优惠券新老用户均可使用，每个ID限领10张，不与其他优惠叠加，每笔订单限用一张；
3、本优惠券仅购买原价商品时可用；
4、内蒙、青海、宁夏、新疆、西藏、港澳台不发货地区不发货，不可使用本优惠券。。
5、优惠券效期为30天，请及时使用，过期作废。

八、满记甜品代金券
1、本券仅限抵扣满记甜品正价商品，不与其他优惠同享，不兑现不找零.
2、每单仅限使用一次，核销后即失效，截屏、复制、修改无效
3、本券不适用于外送服务，仅限到店使用。
4、本券一旦核销，如有退款，优惠金额不返还
5、本券可以在满记甜品全国直营门店使用，加盟店和机场高铁店等特殊门店不支持使用，敬请谅解。
6、本券有效期30天，请及时使用，过期作废。

九、百果园代金券
1、账号绑定手机号充值，充值成功后可登录百果园APP-我的-优惠券查看;
2、本券可直接使用券码在全国百果园线下品牌门店消费使用;
3、每张券需一次性消费完(消费金额需≧券面金额)，单笔订单可叠加使用多张代金券;
4、本电子代金券不记名，售出不退换、不挂失、不能兑换现金;
5、适用范围为百果园全国线下品牌门店、百果园App、百果园小程序，如遇线下门店关闭、装修等停止营业的，以门店实际情况为准，不再另行通知。
6、兑换成功后的有效期为15天，请及时使用，过期作废。

十、良品铺子代金券
1.此卡仅兑付良品铺子门店及APP商城内在售商品（暂不支持支付外卖商品）
2.用户可打开良品铺子APP：我的-礼品卡-添加礼品卡，输入兑换码兑换卡券。
3.APP商城消费，单笔只能选用1张礼品卡支付（单笔支持混合支付）。
4.门店消费，单笔可使用多张礼品卡支付。
5.使用礼品卡购物支付后，不支持退货退款流程。
6、本券有效期30天，请及时使用，过期作废。

十一、肯德基代金券
1.本券为二维码形式，需到店出示二维码由门店扫描验证使用。
2.不可拆分使用，不支持外卖点餐、手机点餐，消费金额超过代金券面值时抵扣使用。
3.肯德基全国直营门店可用（汽车站，火车站，机场等交通枢纽和景区门店除外）。
4.代金券有效期30天，请及时使用，过期作废。

十二、必胜客代金券
1、本券为二维码形式，需到店出示二维码由门店扫描验证使用。
2、不可拆分使用，不支持外卖点餐、手机点餐，消费金额超过代金券面值时抵扣使用。
3、必胜客全国直营门店可用（汽车站，火车站，机场等交通枢纽和景区门店除外）。
4、代金券有效期30天，请及时使用，过期作废。

十三、麦当劳代金券
1.【绑定方式】：本券仅限登录麦当劳官方App、麦当劳微信小程序和麦当劳支付宝小程序，点击“我的”，选择“兑换券”输入兑换码即完成兑换后方可使用。本电子券仅能一次性兑换。                                                                             
2.【使用方式】：请于点餐时登录麦当劳官方App、麦当劳微信小程序或麦当劳支付宝小程序的到店取餐或麦乐送功能的点餐页面下单使用本电子券。
3.【谨慎保管】：本券不记名，不可充值，不找零，不支持挂失。请注意保管，谨防遗失或泄露。
4.【发票】：使用本电子券消费时，麦当劳餐厅不再提供发票。
5.【不可转赠】：本电子券不支持转赠。
6.【优惠不叠加】：本电子券不可以与已享受其他优惠同时叠加优惠使用.
7、本券有效期30天，请及时使用，过期作废。

十四、猫眼电影代金券
1.登录猫眼电影APP-我的-优惠券-绑定，限电影选座使用；购票时，请在“活动与抵用券”，选择使用该券，不可叠加，不能与优惠同享；成功下单后抵用券作废。
2.代金券需在票面价格【5.01】元或以上可以使用；
3.该商品不支持退券、换券；兑换码是否有效以核实为准。
4、代金券同一批券码每个账户限绑定1张（同一账号、手机号、设备、或支付账号相同，即视为同一账户）绑定后7天内有效，请及时使用，过期作废。
5、本券有效期30天，请及时使用，过期作废。
        `,
        车主畅享会员: `一、车主通用券
1.本红包仅支持支付宝【出行-车生活】相关的加油、充电、高速MTC、停车场景，以及支付宝代驾小程序、支付宝租车小程序、支付宝洗车小程序、ETC服务小程序使用。
2.本红包在交易时支持叠加使用，最多叠加10个。本红包不可兑换现金，不可找零。
3.若使用红包的交易发生退款，红包将退回到原支付宝账号，使用有效期不变，退还红包在原有效期内可继续使用；如在原有效期外，则红包失效，不支持再次使用。
4.本红包仅限在中国大陆地区使用，限实名认证用户才可使用。
5.本券到账后有效期30天，过期作废，请及时使用。

二、腾讯视频月卡
1.本商品为腾讯视频VIP会员，支持QQ、微信号、手机号（需绑定账号）充值。
2.本商品不支持电视，仅支持手机、电脑 、平板三端。
3.请确保充值账号正确性，虚拟商品，无法退货。
4.本券到账后有效期30天，过期作废，请及时使用。

三、爱奇艺月卡
1.本商品为爱奇艺黄金会员直充，仅支持手机号码充值。
2.黄金会员不支持电视端使用，仅支持手机、电脑和平板三端使用。
3.请确定充值账号正确性；虚拟商品，无法退货。
4.本券到账后有效期30天，过期作废，请及时使用。

四、优酷视频月卡
1.本商品为优酷VIP直充，仅支持手机号码充值。
2.本商品不支持电视端，仅支持手机、电脑、平板使用。
3.PC端会员同一时间只能登录一个会员设备。
4.请确定充值账号的正确性，虚拟商品，无法退货。
5.本券到账后有效期30天，过期作废，请及时使用。

五、哔哩哔哩月卡
1.本商品目前仅支持手机号码充值。
2.本品仅支持手机、电脑、平板使用，不支持电视端。
3.请确定充值账号正确性；虚拟商品，无法退货。
4.本券到账后有效期30天，过期作废，请及时使用。

六、芒果TV月卡
1.本商品为芒果TV-PC移动会员直充，目前仅支持手机号码充值。
2.适用平台：仅支持手机 、平板、电脑三端。
3.请确定充值账号的正确性；虚拟商品，无法退货。
4.本券到账后有效期30天，过期作废，请及时使用。

七、喜马拉雅月卡
1.本商品为喜马拉雅直充，支持手机号码充值。、
2.充值成功后，可以在喜马拉雅APP-使用手机号登录-点击首页下方vip页面即可查看。
3.请确定充值账号的正确性，虚拟商品，一经充值成功，无法退货。
4.本券到账后有效期30天，过期作废，请及时使用。

八、网易云音乐月卡
1.本商品为网易云直充，目前仅支持手机号码充值。
2.请确定充值号码与绑定号码是否一致（打开网易云音乐-账号-设置-账号与隐私-账号和绑定设置-手机号），一经充值，无法退货。
3.本券到账后有效期30天，过期作废，请及时使用。

九、懒人听书月卡
1.本商品为懒人听书会员直充,权益会自动充值到您的订购的手机号码。
2.到账查询:登陆懒人听书APP-我的查看到账信息,具体会员权益以APP展示为准。
3.到账后有效期30天,过期作废,请及时使用。

十、酷我音乐月卡
1.本商品为酷我会员直充，目前仅支持手机号码充值。
2.酷我豪华包含权益以酷我APP展示为准。
3.请确定充值账号的正确性，虚拟商品，无法退货。
4.本券到账后有效期30天，过期作废，请及时使用。

十一、酷狗音乐月卡
1.本商品为酷狗会员直充，请提交绑定了酷狗账号的手机号充值。
2.到账查询:登录手机APP-我的或电脑端头像查看到账信息；具体会员权益以APP展示为准。
3.请确定充值账号是否已经绑定酷狗账号，一经充值成功将不支持退换。
4.本券到账后有效期30天，过期作废，请及时使用。

十二、滴滴快车优惠券
1.本券仅支持账号绑定手机号码充值。请用户在充值前确保号码已开通滴滴账号。
2.本券为虚拟商品，一经提交充值不支持退换。
3.一笔订单只能使用一张优惠券，并且只能抵扣充值到账户之后的生成的订单，优惠券不拆分、不找零，请合理选择是否使用优惠券，优惠不足部分需用电子支付方式补足。拼车、特惠快车无法使用。
4.本券到账后有效期30天，过期作废，请及时使用。

十三、滴滴专车优惠券
1.本券仅支持账号绑定手机号码充值。请用户在充值前确保号码已开通滴滴账号。
2.本券为虚拟商品，一经提交充值不支持退换。
3.一笔订单只能使用一张优惠券，并且只能抵扣充值到账户之后生成的订单，优惠券不拆分、不找零，请合理选择是否使用优惠券。
4.本券到账后有效期30天，过期作废，请及时使用。

十四、高德打车优惠券
1.本券仅支持账号绑定手机号码充值，请用户在充值前确保号码已开通高德账号。
2.本券仅限在高德地图APP打车时使用，出租车、一口价订单不能使用。
3.同一订单优惠券最多可使用一张，不可叠加使用;券仅限一次性使用，不可拆分，不可转让，不可提现。
4.本券到账后有效期7天，过期作废，请及时使用。
        `,
      };
      if (!data[this.title]) return;
      this.alert = {
        visibility: true,
        title: "使用说明",
        message: data[this.title] || "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.car-owner-membership {
  //
  .tag {
    font-weight: 400;
    font-size: 28px;
    color: #dadada;
    line-height: 40px;
    text-align: center;
    padding: 36px 0 0;
  }

  .welfare {
    padding: 0 32px;

    .title {
      font-weight: 600;
      font-size: 30px;
      color: #333333;
      line-height: 42px;
      padding: 40px 0 30px;
    }

    .annotation {
      font-weight: 400;
      font-size: 26px;
      color: #999999;
      line-height: 37px;
      padding-left: 16px;
    }
    .card {
      height: 160px;
      background: #ffffff;
      box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 0 20px 0 20px;
      box-sizing: border-box;

      .picture {
        width: 126px;
        height: 126px;
        background: #f6f7fa;
        border-radius: 8px;
        text-align: center;
        margin-right: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          // height: 67px;
          width: 99px;
        }
      }

      .cont {
        font-weight: 400;
        flex: 1;

        .num {
          font-size: 32px;
          color: #333333;
          line-height: 45px;
          padding-bottom: 10px;
        }
        .name {
          font-size: 24px;
          color: #999999;
          line-height: 33px;
        }
      }
      .btn {
        width: 160px;
        height: 62px;
        text-align: center;
        line-height: 62px;
        background: rgba(220, 236, 255, 0.54);
        border-radius: 31px;
        border: 2px solid #4d9dff;
        font-weight: 400;
        font-size: 28px;
        color: #4d9dff;
        box-sizing: border-box;

        &.prohibit {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 31px;
          border: 2px solid rgba(0, 0, 0, 0.1);
          color: #ffffff;
        }
      }
    }

    .list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 27px;

      &-item {
        width: 210px;
        height: 280px;
        background: #ffffff;
        box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        text-align: center;
        padding-top: 17px;
        box-sizing: border-box;

        &.prohibit {
          opacity: 0.5;
        }

        &.active {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/receive_zhengxing/ylq.png")
              center center/contain no-repeat;
          }
        }

        .picture {
          width: 176px;
          height: 126px;
          background: #f6f7fa;
          border-radius: 8px;
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: center;

          img {
            width: 99px;
            // height: 67px;
          }
        }
        .money {
          font-weight: 400;
          font-size: 32px;
          color: #333333;
          line-height: 45px;
          padding: 24px 0 10px;
        }

        .name {
          font-weight: 400;
          font-size: 24px;
          color: #999999;
          line-height: 33px;
        }
      }
    }
  }
  .tag2 {
    font-weight: 400;
    font-size: 24px;
    color: #999999;
    line-height: 33px;
    padding: 40px 0 100px;
    text-align: center;
  }
}
</style>
